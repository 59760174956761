import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';

declare var $: any;

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {
  payment: any[] = []; total = 0; balance = 0; merchants: any[] = []; 
  pay: any; charge: any; exp: any[]; id: number; alerts: any[] = [];
  payments: any[] = []; edit: boolean = false; saving: boolean = false;
  constructor(private api: ManagerService, private set: SetupService, private ext: ExportService, public use: NavComponent) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.formReset();
    if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') { this.getMerchant(); }
  }

  formReset() {
    this.pay = { merchantid: this.use.active.merchantid, amount: 0, charge: 0, total: 0, type: 'Invoice', data: [], payment: true, userid: this.use.active.id, date: new Date(), mdate: new Date()  };
    this.balance = this.use.brn.smsbalance;
    this.saving = false;
    // this.getAll();
    this.payment = [{ name: 'Harmony Alabi', mobile: '0502666774', amount: 300, email: 'info@paynowafrica.com', number: '12345666', lastpaymentdate: new Date() }];
    // if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') { this.getMerchant(); }
  }

  getAll() {
    // this.api.get('charge/merchant/send/'+this.use.active.merchantid).subscribe((data: any) => {
    //   this.charge = data;
    // }, (err: HttpErrorResponse) => {
    //   this.saving = true;
    //   this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Charge');
    // });
    this.api.get('alert').subscribe((data: any) => {
      this.alerts = data;
    }, (err: HttpErrorResponse) => {
      this.saving = true;
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Charge');
    });
  }

  getMerchant() {
    this.set.getCompanys().subscribe((data: any[]) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  postBulkPay() {
    this.saving = true;
    this.api.post('Integration/DebtUpload/'+this.pay.type,this.payments).subscribe((data: any) => {
      this.formReset(); 
      this.use.showMsg('success', 'Request Sent', 'Bill Request send Successfully');
    }, (err) => {
      this.saving = false; 
      console.log(err)
      this.use.showMsg('error', 'Bulk Bill Upload Fail', err.message || 'Unable to Send Bulk Bill Request');
    });
  }

  read(event) {
    // this.img = event.target.files[0];
    if(event.target.files[0]) {
      this.ext.readExcel(event.target.files[0]);
      setTimeout(() => { 
        // this.upload = this.ext.import
        this.pay.data = this.formatReads(this.ext.import);
        console.info(this.exp);
        this.payments = this.pay.data;
        setTimeout(() => {
          this.pay.total = this.getTotal(this.pay.data);
          $('.footable').footable();
        }, 3000);
      }, 3000);
    }else {
      this.use.showMsg('error', 'File Selection', 'Please Select a valid Excel file');
    }
  }

  getTotal(trans) {
    console.log(trans);
    this.total = 0; this.pay.amount = 0; //this.pay.charge = 0;
    trans.forEach((e: any) => {
      // if (e.code === 1) {
        this.total += e.amount;
        this.pay.amount += e.amount;
        //this.pay.charge += e.charge;
      // }
    });
    this.pay.amount = this.pay.amount.toFixed(2);
    //this.pay.charge = this.pay.charge.toFixed(2);
    return this.total;
  }

  export() {
    // if (this.payment.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'ecg-bulkbill-sample');
      this.use.showMsg('success', 'Bulk-Pay Template Downloaded', 'Check Downloaded Excel File For Details');
    // } else {
    //   this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    // }
  }

  format() {
    this.id = 1; this.exp = [];
    this.payment.forEach((e: any) => {
      const ed = {
        SN: this.id, accountNumber: e.number, accountName: e.name, mobileNumber: e.mobile, emailAddress: e.email,
        debtAmount: e.amount, lastPaymentDate: e.lastpaymentdate,
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  formatReads(values) {
    this.id = 1; this.exp = [];
    values.forEach((e: any) => {
      const ed = {
        SN: this.id, number: e.accountNumber, name: e.accountName, mobile: e.mobileNumber, email: e.emailAddress || 'ecg@paynowafrica.com',
        amount: e.debtAmount, lastpaymentdate: e.lastPaymentDate, userid: this.use.active.id, merchantid: this.use.active.merchantid,
        status: 'Uploaded', sms: false, amount_paid: 0, date: new Date()
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  getCharge(amount) {
    if(this.charge) {
    var charges = 0;
      if(this.charge.percentage) {
        charges = (amount * this.charge.value) / 100;
        if(charges < this.charge.minimum) charges = this.charge.minimum;
        if(charges > this.charge.maximum) charges = this.charge.maximum;
      } else {
        charges = this.charge.amount;
      }
      console.log(charges)
      return charges;
    }
    return 0;
  }

}
