import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SetupService } from 'src/app/services/setup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {
  @ViewChild('itemForm') public any: NgForm; public files: any[]; Sell: boolean;
  edit: boolean; item: any; id: any; merchant: any;
  image = environment.imageUrl + 'Items/'; public regex = new RegExp(' ','g');

  // tslint:disable-next-line:max-line-length
  constructor(private api: ManagerService, private set: SetupService, private router: Router, private rout: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.id = this.rout.snapshot.paramMap.get('id');
    this.getAll(this.id);
    if (this.use.active.merchantid == null) {
      this.getMerchant();
    }
  }

  getAll(id) {
    if (id && id !== 0) {
      this.edit = true;
      this.getItem(id);
    }
  }

  async addSlug(event) {
    if(event.target.value != null && !this.item._id && this.item.slug != null) { this.item.slug = event.target.value.toLowerCase().replace(this.regex,'-') }
  }

  getItem(id) {
    this.api.getItemByCode(id).subscribe((data: any) => {
      this.item = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Item Detail');
    });
  }

  getMerchant() {
    this.set.getCompanys().subscribe((data: any) => {
      this.merchant = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  fileUpload(event) {
    this.api.Upload(event.target.files[0], 'Items').subscribe((data: any) => {
      this.item.image = this.image + data; console.log(this.image, data);
      console.log(this.item.image);
    });
  }

  postItem() {
    this.api.postItem(this.item).subscribe((data: any) => {
      this.item = data; this.formReset(this.any);
      this.use.showMsg('success', 'Item Added', 'New Item Added Successfully');
      this.router.navigate(['items']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Add Fail', 'Unable to Fetch Item Detail');
    });
  }

  putItem() {
    this.api.putItem(this.item.itemid, this.item).subscribe((data: any) => {
      this.item = data; this.formReset(this.any);
      this.use.showMsg('success', 'Item Updated', 'Item Details Updated Successfully');
      this.router.navigate(['items']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Add Fail', 'Unable to Fetch Item Detail');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      const merch = this.use.active.merchantid || '';
      this.item = { image: 'assets/img/paynow-logo.png',
        itemid: 0, code: '', name: '', description: '', delivery: 0,
        discount: 0, price: 0, status: true, amount: 0, category: '', quantity: 0,
        merchantid: merch, userid: this.use.active.id, date: new Date()
      };
    }
  }

}
