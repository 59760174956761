import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-subcribers',
  templateUrl: './subcribers.component.html',
  styleUrls: ['./subcribers.component.scss']
})
export class SubcribersComponent implements OnInit {
  subcribers = []; filtered = [];
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ManagerService, private notify: NotificationService) { }

  ngOnInit() {
    this.getAllSubcribers();
  }

  getAllSubcribers() {
    this.api.getSubcribers().subscribe((data: any) => {
      this.subcribers = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this.notify.showMsg('error', 'Fetching Fail', 'Unable to Fetch Subcriber List');
    });
  }

  getSubcriber(value) {

  }

  export() {

  }

  searchResult(searchString: string) {
    // console.log(searchString);
    // tslint:disable-next-line:max-line-length
    return this.subcribers.filter(a => a.firstname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.lastname.indexOf(searchString) !== -1 || a.message.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.lastseen.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
