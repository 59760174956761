import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-sequence',
  templateUrl: './setup-sequence.component.html',
  styleUrls: ['./setup-sequence.component.css']
})
export class SetupSequenceComponent implements OnInit {
  @ViewChild('sequenceForm') public any: NgForm;
  edit: boolean; list: boolean; seq: any; sequence: any[] = []; response: any; res: any; search = '';
  constructor(private api: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllSequence();
  }

  getAllSequence() {
    this.api.getSequences().subscribe((data: any) => {
      this.sequence = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Sequence List'); });
  }

  getMore(page,limit) {
    // console.log(page,limit);
    this.api.get('Settlement?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.sequence = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  editSeq(data) {
    this.seq = this.sequence.find(s => s.sequenceid === data);
    // this.seq = data;
    this.edit = true; this.list = true;
  }
  delSeq(dat) {
    this.api.delSequence(dat.sequenceid).subscribe((data: any) => {
      this.res = data; this.getAllSequence();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Sequence'); });
  }

  postSequence() {
    this.api.postSequence(this.seq).subscribe((data: any) => {
      this.res = data; this.sequence.push(data);
      this.formReset(this.any); this.list = false;
      this._use.showMsg('success', 'Sequence Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Sequence');
    });
  }

  putSequence() {
    this.seq.muserid = this._use.active.id; this.seq.mdate = new Date();
   this.api.putSequence(this.seq.sequenceid, this.seq).subscribe((data: any) => {
      this.res = data; this.getAllSequence();
      this.formReset(this.any); this.back();
      this._use.showMsg('success', 'Sequence Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.seq = { sequenceid: 0, name: '', prefix: '', counter: '', length: 0,
      userid: this._use.active.id, date: this._use.date, muserid: null, mdate: null };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false; this.edit = false;
  }

}
