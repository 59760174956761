import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule, NgForm, NgModel } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessagingModule, AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

import { LoginComponent } from './views/login/login.component';
import { LockComponent } from './views/login/lock/lock.component';
import { ForgetPasswordComponent } from './views/login/forget-password/forget-password.component';

import { NavComponent } from './traits/nav/nav.component';
import { FootComponent } from './traits/foot/foot.component';
import { LoaderComponent } from './traits/loader/loader.component';

import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { SetupLicenceComponent } from './views/setup/setup-licence/setup-licence.component';
import { ItemComponent } from './views/manager/item/item.component';
import { AddItemComponent } from './views/manager/item/add-item/add-item.component';
import { ItemDetailComponent } from './views/manager/item/item-detail/item-detail.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { SubcribersComponent } from './views/manager/subcribers/subcribers.component';
import { PaymentsComponent } from './views/manager/payments/payments.component';
import { PackagesComponent } from './views/manager/packages/packages.component';
import { OrdersComponent } from './views/manager/orders/orders.component';
// import { PurchasesComponent } from './views/manager/purchases/purchases.component';
import { StocksComponent } from './views/manager/item/stocks/stocks.component';
import { UtilityComponent } from './views/manager/utility/utility.component';
import { SettlementComponent } from './views/manager/settlement/settlement.component';
import { PaymentComponent } from './views/manager/utility/payment/payment.component';

import { SigninService } from './services/signin.service';
import { SetupService } from './services/setup.service';
import { ManagerService } from './services/manager.service';
import { AuthGuard } from './guard/auth.guard';
import { ExitpageGuard } from './guard/exitpage.guard';
import { FirebaseService } from './services/firebase.service';
import { NotificationService } from './services/notification.service';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorsHandler } from './interceptors/errors-handler';

import * as $ from 'jquery';
import { TopupComponent } from './views/manager/topup/topup.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { TopUpsComponent } from './views/manager/utility/top-ups/top-ups.component';
import { LogsComponent } from './views/manager/utility/logs/logs.component';
import { CreatePaymentComponent } from './views/manager/payments/create-payment/create-payment.component';
import { CustomersComponent } from './views/manager/customers/customers.component';
import { ViewCustomerComponent } from './views/manager/customers/view-customer/view-customer.component';
import { InvoicesComponent } from './views/manager/invoices/invoices.component';
import { CreateInvoiceComponent } from './views/manager/invoices/create-invoice/create-invoice.component';
import { PaginationComponent } from './traits/pagination/pagination.component';
import { InvoiceDetailComponent } from './views/manager/invoices/invoice-detail/invoice-detail.component';


export function tokenGetter() {
  return JSON.parse(localStorage.getItem('Paynow'));
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LockComponent,
    LoaderComponent,
    ForgetPasswordComponent,
    NavComponent,
    FootComponent,
    DashboardComponent,
    SetupCompanyComponent,
    SetupBranchComponent,
    SetupSequenceComponent,
    SetupUserComponent,
    SetupSmslogsComponent,
    SetupSmsComponent,
    NotfoundComponent,
    SetupLocationComponent,
    SetupSessionComponent,
    SetupLicenceComponent,
    ItemComponent,
    AddItemComponent,
    ItemDetailComponent,
    AlertsComponent,
    SetupSmsComponent,
    SetupSmslogsComponent,
    SubcribersComponent,
    PaymentsComponent,
    PackagesComponent,
    OrdersComponent,
    // PurchasesComponent,
    StocksComponent,
    UtilityComponent,
    SettlementComponent,
    PaymentComponent,
    TopupComponent,
    SetupChargeComponent,
    TopUpsComponent,
    LogsComponent,
    CreatePaymentComponent,
    CustomersComponent,
    ViewCustomerComponent,
    InvoicesComponent,
    CreateInvoiceComponent,
    PaginationComponent,
    InvoiceDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule, AngularFireDatabaseModule,
    AngularFirestoreModule.enablePersistence(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [ SigninService, SetupService, ManagerService, JwtHelperService,
    AuthGuard, ExitpageGuard, FirebaseService, NotificationService,
    AngularFireDatabase, AngularFireMessaging, AngularFirestore,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    // { provide: ErrorHandler, useClass: ErrorsHandler },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
