import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.scss']
})
export class SettlementComponent implements OnInit {
  filtered: any[]; settle: any[]; response: any[] = []; search = '';
  edit: boolean = false; set: any; merchant: any; saving: boolean = false;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ManagerService, private setup: SetupService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getMerchant();
    this.getAllSettlement();
  }

  getMerchant() {
    this.setup.getCompanys().subscribe((data: any) => {
      this.merchant = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  view(value) {
    this.edit = true;
    this.set = value;
    document.getElementById('settle-form').click();
  }

  cancel(value) {
    this.set = value;
    this.set.status = "Cancelled";
    this.putSettlement();
  }

  confirm(val) {
    this.api.get('settlement/Confirm/'+ val.settlementid).subscribe((data: any) => {
      this.getAllSettlement();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', err.message || 'Unable to Fetch Payment List');
    });
  }

  delete(val) {
    this.api.delSettlement(val.settlementid).subscribe(data=> {
      this.getAllSettlement();
      this.use.showMsg('success', 'Request Deleted', 'Settlement Request Deleted Successfully');
    },(err:HttpErrorResponse)=> {
      this.use.showMsg('error', 'Delete Fail', err.message || 'Unable to Delete Settlement');
    })
  }

  getAllSettlement() {
    this.api.getSettlements().subscribe((data: any) => {
      // this.settle = data.sort((a, b) => (a.date < b.date) ? 1 : -1);
      this.filtered = data.data; this.response = data;
      // this.settle.sort((a, b) => (a.date > b.date) ? 1 : -1);
      // this.settle.sort((a, b) => (a.date > b.date) ? 1 : -1);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Settlement List Fail', err.message || 'Unable to get Settlement List');
    });
  }

  getMore(page,limit) {
    // console.log(page,limit);
    this.api.get('Settlement?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.filtered = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  postSettlement() {
    this.saving = true;
    this.api.postSettlement(this.set).subscribe((data: any) => {
      this.getAllSettlement();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Settlement Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Settlement Request Fail', err.message || 'Unable to Request Settlement');
    });
  }

  putSettlement() {
    this.api.putSettlement(this.set.settlementid, this.set).subscribe((data: any) => {
      // this.settle.push(data); this.filtered.push(data);
      this.getAllSettlement();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Updated', 'Settlement Request updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Request Update Fail', err.message || 'Unable to Update Settlement Request');
    });
  }

  selected(event) {
    console.log(event.target.value);
    // this.filtered = this.settle.filter(a => a.merchantid == event.target.value);
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.settle.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  formReset() {
    this.set = { code: null, title: '', start: '', end: '',
      charge: 0, status: 'Pending', amount: 0, period: 0, merchantid: this.use.active.merchantid,
      userid: this.use.active.id, date: new Date()
    };
    this.edit = false;
  }

}
