import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  get(route) {
    return this.http.get(this.api + route);
  }

  post(rout, data): Observable<any> {
    return this.http.post(this.api + rout, JSON.stringify(data));
  }

  put(rout, data): Observable<any> {
    return this.http.put(this.api + rout, JSON.stringify(data));
  }

  delete(rout): Observable<any> {
    return this.http.delete(this.api + rout);
  }

  getCode(mobile: string): Observable<any> {
    return this.http.get(this.api + 'bot/otp/' + mobile);
  }

  getDashboard(): Observable<any> {
    return this.http.get(this.api + 'Dashboard');
  }

  getSequences(): Observable<any> {
    return this.http.get(this.api + 'sequence');
  }

  getSequence(id: number): Observable<any> {
    return this.http.get(this.api + 'sequence/' + id);
  }

  postSequence(data): Observable<any> {
    return this.http.post(this.api + 'sequence', JSON.stringify(data));
  }

  putSequence(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sequence/' + id, JSON.stringify(data));
  }

  delSequence(id: number): Observable<any> {
    return this.http.delete(this.api + 'sequence/' + id);
  }

  getCountrys(): Observable<any> {
    return this.http.get(this.api + 'country');
  }

  getCountry(id: number): Observable<any> {
    return this.http.get(this.api + 'country/' + id);
  }

  postCountry(data): Observable<any> {
    return this.http.post(this.api + 'country', JSON.stringify(data));
  }

  putCountry(id: number, data): Observable<any> {
    return this.http.put(this.api + 'country/' + id, JSON.stringify(data));
  }

  delCountry(id: number): Observable<any> {
    return this.http.delete(this.api + 'country/' + id);
  }

  getRegions(): Observable<any> {
    return this.http.get(this.api + 'region');
  }

  getRegion(id: number): Observable<any> {
    return this.http.get(this.api + 'region/' + id);
  }

  postRegion(data): Observable<any> {
    return this.http.post(this.api + 'region', JSON.stringify(data));
  }

  putRegion(id: number, data): Observable<any> {
    return this.http.put(this.api + 'region/' + id, JSON.stringify(data));
  }

  delRegion(id: number): Observable<any> {
    return this.http.delete(this.api + 'region/' + id);
  }

  getLocations(): Observable<any> {
    return this.http.get(this.api + 'location');
  }

  getLocation(id: number): Observable<any> {
    return this.http.get(this.api + 'location/' + id);
  }

  postLocation(data): Observable<any> {
    return this.http.post(this.api + 'location', JSON.stringify(data));
  }

  putLocation(id: number, data): Observable<any> {
    return this.http.put(this.api + 'location/' + id, JSON.stringify(data));
  }

  delLocation(id: number): Observable<any> {
    return this.http.delete(this.api + 'location/' + id);
  }

  getCompanys(): Observable<any> {
    return this.http.get(this.api + 'merchant');
  }

  getCompany(id: number): Observable<any> {
    return this.http.get(this.api + 'merchant/' + id);
  }

  postCompany(data): Observable<any> {
    return this.http.post(this.api + 'merchant', JSON.stringify(data));
  }

  putCompany(id: number, data): Observable<any> {
    return this.http.put(this.api + 'merchant/' + id, JSON.stringify(data));
  }

  delCompany(id: number): Observable<any> {
    return this.http.delete(this.api + 'merchant/' + id);
  }

  uploadCompany(data): Observable<any> {
    return this.http.post(this.api + 'merchant/batch', JSON.stringify(data));
  }

  getAllBranchs(): Observable<any> {
    return this.http.get(this.api + 'process');
  }

  getBranchs(): Observable<any> {
    return this.http.get(this.api + 'branch');
  }

  getBranch(id: number): Observable<any> {
    return this.http.get(this.api + 'branch/' + id);
  }

  getBranchbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'branch/code/' + id);
  }

  postBranch(data): Observable<any> {
    return this.http.post(this.api + 'branch', JSON.stringify(data));
  }

  uploadBranch(data): Observable<any> {
    return this.http.post(this.api + 'branch/batch', JSON.stringify(data));
  }

  putBranch(id: number, data): Observable<any> {
    return this.http.put(this.api + 'branch/' + id, JSON.stringify(data));
  }

  delBranch(id: number): Observable<any> {
    return this.http.delete(this.api + 'branch/' + id);
  }

  getAlerts(): Observable<any> {
    return this.http.get(this.api + 'alert');
  }

  getMainAlerts(): Observable<any> {
    return this.http.get(this.api + 'alert/main');
  }

  getAlertByType(type: string): Observable<any> {
    return this.http.get(this.api + 'alert/type/' + type);
  }

  getAlert(id: number): Observable<any> {
    return this.http.get(this.api + 'alert/' + id);
  }

  postAlert(data): Observable<any> {
    return this.http.post(this.api + 'alert', JSON.stringify(data));
  }

  putAlert(id: number, data): Observable<any> {
    return this.http.put(this.api + 'alert/' + id, JSON.stringify(data));
  }

  delAlert(id: number): Observable<any> {
    return this.http.delete(this.api + 'alert/' + id);
  }

  getAcctTypes(): Observable<any> {
    return this.http.get(this.api + 'accounttype');
  }

  getAcctType(id: number): Observable<any> {
    return this.http.get(this.api + 'accounttype/' + id);
  }

  postAcctType(data): Observable<any> {
    return this.http.post(this.api + 'accounttype', JSON.stringify(data));
  }

  putAcctType(id: number, data): Observable<any> {
    return this.http.put(this.api + 'accounttype/' + id, JSON.stringify(data));
  }

  delAcctType(id: number): Observable<any> {
    return this.http.delete(this.api + 'accounttype/' + id);
  }

  getSmsApis(): Observable<any> {
    return this.http.get(this.api + 'smsapi');
  }

  getSmsApi(id: number): Observable<any> {
    return this.http.get(this.api + 'smsapi/' + id);
  }

  postSmsApi(data): Observable<any> {
    return this.http.post(this.api + 'smsapi', JSON.stringify(data));
  }

  putSmsApi(id: number, data): Observable<any> {
    return this.http.put(this.api + 'smsapi/' + id, JSON.stringify(data));
  }

  delSmsApi(id: number): Observable<any> {
    return this.http.delete(this.api + 'smsapi/' + id);
  }

  getSmslogs(): Observable<any> {
    return this.http.get(this.api + 'sms');
  }

  getSmslogsDistinct(): Observable<any> {
    return this.http.get(this.api + 'sms/distinct');
  }

  getSmslog(id: number): Observable<any> {
    return this.http.get(this.api + 'sms/' + id);
  }

  postSmslog(data): Observable<any> {
    return this.http.post(this.api + 'sms', JSON.stringify(data));
  }

  putSmslog(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sms/' + id, JSON.stringify(data));
  }

  delSmslog(id: number): Observable<any> {
    return this.http.delete(this.api + 'sms/' + id);
  }

  getUsers(): Observable<any> {
    return this.http.get(this.api + 'auth/users');
  }

  getUser(id: number): Observable<any> {
    return this.http.get(this.api + 'auth/user/' + id);
  }

  resetUser(email): Observable<any> {
    return this.http.get(this.api + 'auth/reset/' + email);
  }

  postUser(data): Observable<any> {
    return this.http.post(this.api + 'auth/user', JSON.stringify(data));
  }

  putUser(id: string, data): Observable<any> {
    return this.http.put(this.api + 'auth/user/' + id, JSON.stringify(data));
  }

  delUser(id: number): Observable<any> {
    return this.http.delete(this.api + 'auth/user/' + id);
  }

  getLicences(): Observable<any> {
    return this.http.get(this.api + 'licence');
  }

  getLicence(id: number): Observable<any> {
    return this.http.get(this.api + 'licence/' + id);
  }

  postLicence(data): Observable<any> {
    return this.http.post(this.api + 'licence', JSON.stringify(data));
  }

  putLicence(id: number, data): Observable<any> {
    return this.http.put(this.api + 'licence/' + id, JSON.stringify(data));
  }

  delLicence(id: number): Observable<any> {
    return this.http.delete(this.api + 'licence/' + id);
  }


}
