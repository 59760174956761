import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';

declare var $: any;

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {
  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  types: any[] = [ "TITHE", "OFFERING", "DONATION", "RECEIVE", "SEND" ]; edit: boolean = false;
  topup: any; saving: boolean = false;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
    this.getTotal(this.filtered);
  }
  constructor(private api: ManagerService, private set: SetupService, private ext: ExportService, private use: NavComponent) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.rpt = { merchantid: this.use.active.merchantid, type: '', start: '', end: '', settled: false, code: 0 };
    this.formReset();
    this.getAllTopup();
    if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') { this.getMerchant(); }
  }

  formReset() {
    this.topup = { merchantid: this.use.active.merchantid, mobile: "", network: "", amount: "", reference: "", status_message: "PENDING", payment: true, userid: this.use.active.id, date: new Date(), mdate: new Date()  };
  }

  getMerchant() {
    this.set.getCompanys().subscribe((data: any[]) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  postTopup() {
    this.saving = true;
    this.api.post('topup',this.topup).subscribe((data: any) => {
      this.getAllTopup(); this.saving = false;
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Topup Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Topup Request Fail', err.message || 'Unable to Request Topup');
    });
  }

  getAllTopup() {
    this.api.get('Topup').subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Topup List');
    });
  }

  generate() {
    this.api.post('Topup/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Topup List');
    });
  }

  getTotal(trans) {
    // console.log(trans);
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, MOBILE: e.mobile, NETWORK: e.network,
        AMOUNT: e.amount, REFERENCE: e.reference, RESPONSE: e.status_message, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    // console.log(searchString);
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1 ) !== -1);
  }

}
