import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  invoices: any; filtered: any[] = []; 
  response: any; search: string =''; invoice: any; st: any; id = 0; exp: any; upload:any[]=[];
  // tslint:disable-next-line:variable-name
  private _searchTitle: string;

  get searchTitle(): string {
    return this._searchTitle;
  }
  set searchTitle(value: string) {
    this._searchTitle = value;
    this.search = this._searchTitle;
    this.getInvoice();
    // this.filtered = this.searchResult(value);
  }
  constructor(private api: ManagerService, private route: Router, private router: ActivatedRoute, private use: NavComponent, public ext: ExportService) { }

  ngOnInit() {
    this.getInvoice();
  }

  getInvoice(page=1, limit=10) {
    this.api.get('invoice?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.filtered = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Invoice List');
    });
  }

  getMore(page,limit) {
    console.log(page,limit);
    this.api.get('invoice?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.filtered = data.data;this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  Update() {
    // console.log(page,limit);
    this.api.get('service').subscribe((data: any) => {
      // this.filtered = data.data;this.response = data;
      this.use.showMsg('success', 'Debt Request Trizzered', 'Debt has been requested successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  disable(val) {
    val.status = "Cancel";
    this.api.put('invoice/'+val.invoiceid, val).subscribe((data: any) => {
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Invoice Canceling Failed', 'Unable to Cancel Invoice');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.invoices.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    // document.getElementById('upload').click();
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'invoices');
      this.use.showMsg('success', 'Invoices Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Invoices Export Fail', 'There is no Item Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.name, MOBILE: e.mobile, EMAIL: e.email, OTHER: e.other,
        ACTIVE: e.active, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
